<template>
  <section class="section section-history">
    <div class="inner-section inner-section-grid">
      <div class="inner-section-title">
        <HeadingTitle title="HISTORY">
          <template v-slot:before>
            <div class="before">
              <span>H</span>
            </div>
          </template>
        </HeadingTitle>
      </div>
      <div class="inner-section-grid-second-div inner-section-history">
        <div class="history-container" v-for="row in history" :key="row">
          <div class="history-year">
            <h3>{{ row.year }}</h3>
          </div>
          <div class="history-item">
            <div class="history-li" v-for="li in row.list" :key="li">
              <div class="history-li-item">
                <p class="month">{{ li.month }}</p>
                <p class="content">{{ li.content }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { reactive } from "vue";
import HeadingTitle from "../Elements/HeadingTitle.vue";

export default {
  components: {
    HeadingTitle,
  },
  setup() {

    const history = reactive([
      {
        year: "2025", list: [
          { month: "03", content: `환경경영시스템 ISO 14001 인증 획득` },
          { month: "", content: `모햇 누적 가입자 수 16만 명 돌파` },
          { month: "", content: `모햇 발전소 2,300개소 돌파` },
          { month: "", content: `모햇 누적 투자금액 2,800억 원 돌파` },
          { month: "01", content: `창업육성플랫폼 'IBK창공' 혁신기업 선정` },
        ]
      },
      {
        year: "2024", list: [
          { month: "12", content: `과학기술정보통신부 '2024 하반기 우수기업연구소' 지정` },
          { month: "11", content: `대한민국 Digital Innovation Award '과학기술정보통신부 장관' 표창` },
          { month: "", content: `한국에너지대상 재생에너지산업발전 부문 '산업통상자원부 장관' 표창` },
          { month: "", content: `중소기업 기술·경영 혁신대전 '중소벤처기업부 장관' 표창 2관왕` },
          { month: "09", content: `서울특별시 ‘2024 하이서울기업’ 선정` },
          { month: "", content: `스틱인베스트먼트 200억, 대형은행 100억, 포스코기술투자 30억, 어센도벤처스 10억 투자유치 등 Pre-IPO 완료` },
          { month: "08", content: `누적 투자금액 1800억원 돌파, 회원수 10만명 돌파` },
          { month: "06", content: `중소벤처기업부 '2024 초격차 스타트업 1000+ 프로젝트' 친환경·에너지 분야 선정` },
          { month: "05", content: `경상북도 경제진흥원 ESG 정책 업무협약 체결` },
          { month: "04", content: `누적 투자금액 1500억원 돌파` },
          { month: "", content: `에너지 기술력 등급 AA 획득` },
          { month: "", content: `중소기업기술정보진흥원 '2024 TIPA 밸류업 프로그램 데모데이' 최우수상 수상` },
          { month: "", content: `E-모빌리티 혁신기술상 수상` },
          { month: "01", content: `고용노동부 <2024년도 청년친화강소기업>` },
        ]
      },
      {
        year: "2023", list: [
          { month: "12", content: `에이치에너지 함일한 대표이사 정보통신 중소기업 발전 유공자 포상 (과기정통부장관 표창)` },
          { month: "", content: `<2023 경상북도 중소기업대상> 우수상` },
          { month: "11", content: `2023 「행복한 중기 일자리대상」 우수상 수상 확정 (중소벤처기업진흥공단 이사장상)` },
          { month: "10", content: `환경부 사업 총괄 관리 <2023년도 녹색자산유동화증권 발행 지원사업> 20억원 녹색유동화채권 발행(하이투자증권)` },
          { month: "", content: `중소벤처기업부  "2023 중소기업 R&D 우수성과 대표기업" 선정 (사업화 분야)` },
          { month: "09", content: `포스코홀딩스 2기 유니콘 후보기업 선정 (태양광 기반 재생에너지 플랫폼)` },
          { month: "08", content: `에이치에너지 함일한 대표이사 울산 분산에너지특화지역 추진 위원 위촉` },
          { month: "", content: `경상북도 경북창조경제혁신센터 "경상북도 예비유니콘 성장지원사업" 에너지자원환경 분야 선정 기업` },
          { month: "07", content: `2023 대한민국 산업대전 에너지 신기술 부문 산업통상자원부 장관상 수상` },
          { month: "06", content: `기업 RE100 원스톱 달성 서비스 “모두의 RE100” 출시` },
          { month: "", content: `에이치에너지 RE100 달성` },
          { month: "03", content: `규제샌드박스 혁신기업 간담회 규제샌드박스 대표 승인기업` },
          { month: "02", content: `"2023 대한민국 산업대상" '에너지 투자 플랫폼' 부문 K-스타트업(StartUp) 대상 3년 연속 수상(머니투데이)` },
        ]
      },
      {
        year: "2022", list: [
          { month: "12", content: `소규모 태양광 전력거래 플랫폼 규제샌드박스 실증특례 한국전력공사 업무협약 2022.12.29` },
          { month: "09", content: `캠페인 RE100thINGS(알이백띵스) 전개` },
          { month: "07", content: `모햇회원 1만명 돌파` },
          { month: "06", content: `김태양, 김태양T 서비스 론칭` },
          { month: "", content: `KB인베스트먼트 약 70억원 규모 Series B 투자 유치` },
          { month: "05", content: `산업통상자원부 "2022년 지역 에너지신산업 활성화 지원사업 지원 대상 선정"` },
          { month: "03", content: `"2022 대한민국 산업대상" '에너지 공유 플랫폼' 부문 K-스타트업(StartUp) 대상 2년 연속 수상(머니투데이)` },
          { month: "01", content: `"2022년도 고객감동경영대상" O2O플랫폼부문 수상 2년 연속 수상(한국경제)` },
        ]
      },
      {
        year: "2021", list: [
          { month: "11", content: `G-Star Award 대상(중소벤처기업부 장관상)수상` },
          { month: "", content: `에이치에너지 화성도시공사, 화성시사회복지재단과 MOU체결` },
          { month: "", content: `국회 "2021년 4차산업혁명 Power Korea 대전" 스마트 에너지공유 플래폼상 2년 연속 수상` },
          { month: "10", content: `"더쉐어스마트매니저" 서비스 오픈` },
          { month: "", content: `"경북우리집RE100 경북도 2021 적극행정 우수사례 경진대회 대상 수상"` },
          { month: "09", content: `"울산시민가상발전소 준공식"` },
          { month: "07", content: `"2021년 대한민국 산업대상" 지속가능 부문 상장 수상` },
          { month: "06", content: `KB인베스트먼트, 다담인베스트먼트로부터 21억원 규모 시리즈A 투자 유치` },
          { month: "03", content: `"규제샌드박스 특례통과` },
          { month: "", content: `“소규모 태양광 전력거래플랫폼” 실증특례(경북/울산 지역내)"` },
          { month: "", content: `“2021년도 산업대상 K-StartUp 대상” O2O부문 수상(머니투데이)` },
          { month: "01", content: `“2021년도 고객감동경영대상” O2O플랫폼부문 수상(한국경제)` },
        ]
      },
      {
        year: "2020", list: [
          { month: "12", content: `“2020년도 동반성장대상” 최우수협력기업상 수상(동반성장위원회)` },
          { month: "", content: `모햇 사업개시(공유옥상플랫폼 모햇 기반 전국형 가상발전소)` },
          { month: "11", content: `경상북도지사표창 수상` },
          { month: "09", content: `국회 “2020년 4차산업혁명 Power Korea 대전” 스마트 에너지공유 플랫폼상` },
          { month: "06", content: `사업화 과제선정(에너지기술 수용성제고 및 사업촉진사업)` },
          { month: "03", content: `경북시민가상발전소 사업개시(경북도, 경북우리집RE100협동조합)` },
          { month: "01", content: `울산시민가상발전소 사업개시(울산시, 동서발전, 울산스마트에너지협동조합)` },
        ]
      },
      {
        year: "2019", list: [
          { month: "12", content: `한국동서발전(주) 에너지신사업협력이익공유제 파트너(5년)` },
          { month: "04", content: `Series A 투자유치(Enlight Ventures)` },
        ]
      },
      {
        year: "2018", list: [
          { month: "12", content: `동작시민가상발전소 사업개시(동작구, 성대골에너지협동조합 협력사업)` },
          { month: "09", content: `DERShare.kr 플랫폼런칭(분산자원 최적운용, VPP 서비스 런칭)` },
          { month: "06", content: `Seed 투자 유치(포항공과대학교 기술지주(포스텍홀딩스) 등)` },
          { month: "05", content: `TIPS 프로그램 선정(분산자원최적운용 및 전력중개서비스개발)` },
          { month: "03", content: `회사 설립 (설립일 2018.03.27)` },
        ]
      }
    ]);
    return {
      history,
    };
  },
};
</script>
